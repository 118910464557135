import React, { useState } from "react"
import Layout from './layout'
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';



const data = [
 {
  src: "../images/doors/home_reno_after_01.jpg"
 }
]


const Slide = (src) =>{

  return(
    <div className="flex flex-col items-center justify-center px-6 py-16 odd:float-left even:float-right">
      {/* <StaticImage
          loading="eager"
          src={"../images/doors/basement_after.jpg"}
          alt="TN Home Improvements Banner Image"
          placeholder="blurred"
          className=""
          imgClassName="object-top"
          width={2400}
          height={1200}
        /> */}
    </div>
  )
}




const DoorsFraming = () => {
  const [currentIndex, setIndex] = useState(0);
  const slideList = data.map((image, index) =>
  < Slide key={index} src={image.src} />
);


  return (
    <div className="bg-navy">

    <Layout marginTop={true}>
    <div className="bg-navy">
    <div className="relative flex items-center justify-start m-auto max-w-screen-2xl md:h-96">
          <StaticImage
          loading="eager"
        src={"../images/working_05.jpg"}
        alt="TN Home Improvements Doors & Framing Banner Image"
        placeholder="blurred"
        className="!hidden md:!block heroContainer__image"
        imgClassName="object-middle"
        width={4800}
        height={2400}
      />

<StaticImage
          loading="eager"
        src="../images/services_hero_02.jpg"
        alt="TN Home Improvements Doors & Framing Banner Image"
        placeholder="blurred"
        className="md:!hidden"
        imgClassName="object-middle"
        width={2400}
        height={1200}
      />



          <div className="absolute top-0 z-20 p-5 scale-75 rounded-full md:top-auto md:scale-1 ">
          </div>

          <div className="hidden absolute px-8 left-0 z-10 md:flex flex-col items-center justify-center w-full h-full text-white bg-gradient-to-t md:bg-gradient-to-r from-navy from-0%  via-navy/50  to-navy to-100% ">
              <div className="relative flex flex-col items-center pt-20 pr-5 md:pr-0">
                <h1 className="pb-2 m-auto text-4xl text-center text-white border-b-2 border-gold-100">Doors & Framing</h1>
              </div>
            </div>



          </div>
      </div>

<div className="relative flex flex-col items-center pt-20 pr-5 md:pr-0 md:items-start md:hidden">
                <h1 className="pb-2 m-auto text-4xl text-center text-white border-b-2 border-gold-100">Doors & Framing</h1>
              </div>


      <div className="flex flex-row px-12 gap-x-6">
        <div className="flex-1">
          <Carousel
          key="1"
              selectedItem={currentIndex}
              showArrows={false}
              emulateTouch={true}
              infiniteLoop={true}
              interval={12000}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              preventMovementUntilSwipeScrollTolerance={true}
              autoPlay>
                <div className="flex flex-col items-center justify-center py-16 odd:float-left even:float-right">
                    <StaticImage
                        loading="eager"
                        src={"../images/doors/framing_after_02.jpg"}
                        alt="TN Home Improvements Doors & Framing Before Image"
                        placeholder="blurred"
                        className=""
                        imgClassName="object-top"

                      />
                  </div>
                  <div className="flex flex-col items-center justify-center py-16 odd:float-left even:float-right">
                    <StaticImage
                        loading="eager"
                        src={"../images/doors/framing_after_01.jpg"}
                        alt="TN Home Improvements Doors & Framing After Image"
                        placeholder="blurred"
                        className=""
                        imgClassName="object-top"

                      />
                  </div>
          </Carousel>
        </div>
        <div className="flex-1 py-16">
          <div className="px-8 py-16 text-white">
            <h2 className="pb-6 text-2xl text-gold-100">Expert Framing and Door Installation Services</h2>
            <p>The installation of new doors can significantly change the look and feel of a space:</p>
            <ul className="flex flex-col pt-3 list-disc gap-y-1">
                <li>Removed outdated or original doors and prepared the openings for new installations.</li>
                <li>Installed modern doors with frosted glass panels to allow natural light while still providing privacy.</li>
                <li>Trimmed the doors with crisp, white framing to give a fresh and clean-lined aesthetic.</li>
                <li>The doors were selected to match the office's contemporary design, offering a blend of functionality and style.</li>
            </ul>
            <p>This upgrade brings a more professional and polished atmosphere to the environment.</p>
          </div>
        </div>
      </div>
    </Layout>
    </div>
  );
};

export default DoorsFraming;
